import { Execution } from './modules/utility/Decorator';

/**
 * HTML要素にクラス名を追加
 */
@Execution(false, '1060')
export class Initialize {

  /**
   * ユーザーエージェント
   */
  private readonly ua: string = navigator.userAgent;

  /**
   * タブレットのviewport幅
   */
  private readonly tabletViewport: string = '';

  /**
   * クラス名
   */
  private className: string = '';

  /**
   * コンストラクタ
   * @param isAdaptive アダプティブデザインかの真偽値
   * @param tabletViewport タブレットのviewport幅
   */
  constructor(isAdaptive: boolean, tabletViewport: string) {
    this.setHtmlClass();

    if (isAdaptive) {
      this.tabletViewport = tabletViewport;
      this.setTabletViewport();
    }

    this.setLinkImg();
  }

  /**
   * HTML要素にクラス名を追加
   */
  private setHtmlClass(): void {
    //JavaScript有効判定
    this.className += ' js-available';

    //Retina判定
    if (window.devicePixelRatio > 1) {
      this.className += ' retina' + Math.round(window.devicePixelRatio);
    }

    // UA判定
    if (this.ua.match(/iPhone|iPad|iPod/i)) {
      this.className += ' ios';
      if (this.ua.match(/iPad/i)) {
        this.className += ' ipad';
      }
    } else if (this.ua.match(/Windows/i)) {
      this.className += ' win';
      if (this.ua.match(/rv:11/i)) {
        this.className += ' ie ie11';
        this.addPromise();
      }
    } else if (this.ua.match(/android/i)) {
      this.className += ' android';
    } else if (this.ua.match(/Macintosh/i)) {
      this.className += ' mac';
    }

    if (this.ua.match(/MSIE/i)) {
      this.className += ' ie';
      this.addPromise();
      if (this.ua.match(/MSIE 10/i)) {
        this.className += ' ie10';
      } else if (this.ua.match(/MSIE 9/i)) {
        this.className += ' ie9';
      } else if (this.ua.match(/MSIE 8/i)) {
        this.className += ' ie8';
      } else if (this.ua.match(/MSIE 7/i)) {
        this.className += ' ie7';
      } else if (this.ua.match(/MSIE 6/i)) {
        this.className += ' ie6';
      }
    } else if (this.ua.match(/AppleWebkit/i) && this.ua.match(/Edge/i)) {
      this.className += ' edge';
      this.addPromise();
    } else if (this.ua.match(/chrome/i)) {
      this.className += ' chrome';
    } else if (this.ua.match(/firefox/i)) {
      this.className += ' firefox';
    } else if (this.ua.match(/safari/i)) {
      this.className += ' safari';
    }

    // HTML要素にクラス名を追加
    document.getElementsByTagName('html')[0].className = this.className;
  }

  /**
   * タブレットの場合、viewportを変更
   */
  private setTabletViewport(): void {
    if (this.isTablet(this.ua)) {
      const meta: any = document.getElementsByTagName('meta');

      for (let i: number = 0; i < meta.length; i++) {
        const obj: Element = meta[i];
        const name: string | null = obj.getAttribute('name');

        if (name && name.toLowerCase() == 'viewport') {
          obj.setAttribute('content', 'width=' + this.tabletViewport);
          break;
        }
      }
    }
  }

  /**
   * タブレットかどうかを調べる
   * @param ua ユーザーエージェントの文字列
   * @returns タブレットの場合true
   */
  private isTablet(ua: string): boolean {
    if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      return false;
    } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
      return true;
    }

    return false;
  }

  /**
   * IEのPromise対応
   */
  private addPromise(): void {
    const script: Element = document.createElement('script');
    script.setAttribute('src', 'https://www.promisejs.org/polyfills/promise-6.1.0.min.js');
    const head: Element | null = document.querySelector('head');

    if (head) {
      head.appendChild(script);
    }
  }

  /**
   * 記事内のリンク付き画像の範囲修正
   */
  private setLinkImg(): void {
    
  }
}
